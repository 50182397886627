button {
  height: 42px;
  margin: 18px;

  &.kep-login-facebook {
    padding: 4px 8px 0 8px;
    font-size: 14px !important;
    margin-left: 0;
  }

  &.facebookLogout {
    cursor: pointer;
    background-color: #4c69ba;
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    text-transform: uppercase;
  }
}