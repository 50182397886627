@import './lib/common/theme.scss';

h1 {
    color: $primaryTextHighlight1
}

h2 {
    color: $primaryTextHighlight2;
}

h3 {
    color: $primaryTextTitle;
    margin: 0;
}

h4 {
    color: $primaryTextHighlight2;
    margin: 0;
}

table {
    width: 100%;

    thead {
        th {
            color: $primaryTextHighlight2
        }
    }
}

label {
    color: $primaryTextHighlight2;
    display: block;
}


button, .button {
    display: inline-block;
    padding: 4px 16px;
    margin: 0 2px 2px 0;
    border: 2px solid rgba(255,255,255,0);
    border-radius: 2em;
    box-sizing:  border-box;
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    color: $primaryText;
    text-shadow:  0 0.04em 0.04em rgba(0,0,0,0.35);
    text-align: center;
    transition:  all 0.2s;
    background-color: $primaryBackground;
    line-height: 28px;
    font-size: 14px;
}

button:hover, .button:hover {
    border-color: rgba(255,255,255,1);
    cursor: pointer;
}

button:disabled, .button:disabled, .button-disabled {
    border: 2px solid rgba(255,255,255,0) !important;
    line-height: 28px;
    opacity: 0.6;
    cursor: not-allowed !important;
}

ul {
    list-style-type: none;
    color: $secondaryTextHighlight;
    background-color: $primaryBackground;
    border: solid 1px $secondaryTextHighlight;
    margin: 0;
    padding: 4px;

    li.active {
        color: $primaryBackground;
        background-color: $secondaryTextHighlight;
    }
}

fieldset {
    border: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

// Classes =============================================================================
.header {
    display: flex;
    justify-content: space-between;
    background-color: $primaryBackground;
    line-height: $headerHeight;
    border-bottom: $xsmall solid $barrierColor;
}

.main {
    overflow-y: scroll;
    padding: $medium
}

.success {
    color: green;
}

.error {
    color: red;
}

.container {
    margin-bottom: 16px;
}

.button-container {
    display: flex;
    justify-content: center;
}

.dog-owner-grouping {
    border: solid 1px $primaryTextHighlight1;
    padding: 14px 8px 4px 16px;
}

.input-grouping-container {
    border: solid 1px $primaryTextHighlight1;
    margin-right: 8px;
    padding: 8px;
}

.button-grouping-container {
    border: solid 1px $primaryTextHighlight1;
    margin-right: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tile {
    background-color: $tileBackground;
    border: solid 1px $primaryTextHighlight1;
    padding: 4px;
    margin-bottom: 16px;

    h4 {
        text-align: center;
        margin-bottom: 4px;
    }
}

.form-container {
    display: flex;
    flex-wrap: wrap;
}

.input-container {
    margin-right: 8px;
}

.link {
    text-decoration: underline;
    color: $primaryText;
    cursor: pointer;
}

.checkbox-container {
    display: flex;
    
    input[type='checkbox'] {
        cursor: pointer;
    }

    label {
        display: inline;
        cursor: pointer;
    }
}

.radio-container {
    display: flex;
    flex-wrap: wrap;
    
    .radio-option-container {
        margin-right: 8px;

        input[type='radio'] {
            cursor: pointer;
        }

        label {
            display: inline;
            cursor: pointer;
        }
    }
}

.readyonly-table {
    width: 100%;

    thead {
        th {
            text-align: left;
            border-bottom: solid 2px $primaryTextHighlight2;
        }
    }

    tbody {
        tr {
            td {
                padding: 8px;
            }
        }

        tr:nth-child(even) {
            td {
                color: $primaryText;
                border-bottom: solid 1px $primaryText;
            }
        }

        tr:nth-child(odd) {
            td {
                color: $secondaryTextHighlight;
                border-bottom: solid 2px $secondaryTextHighlight;
            }
        }
    }
}

.row-seperator {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: solid 2px $barrierColor;
}

@media print {
    .no-print {
        display: none;
    }

    .main {
        min-height: 8.5in;
    }
}